<template>
  <div class="defaultLayout">
    <slot />
  </div>
</template>

<style lang="scss">
.defaultLayout {
  @apply min-h-screen h-full;

  background-color: var(--base-background-color);
}
</style>
